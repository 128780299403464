import { isBrowser } from "../components/protected-route"
import { appRoutes, mobileRoutes } from "../constants/app-routes"
import { unprotectedRoutes } from "../constants/private-routes"
import cookie from "cookie"
import { handleSignOut, token } from "./auth"
import axios from "axios"
import { getAppHeaders } from "../constants/api-requests"

export const handleRedirect = (noUser, router) => {
    if (noUser !== null) {
        if (noUser) {
            if (unprotectedRoutes.indexOf(router.pathname) === -1) {
                if (isBrowser) {
                    router.push(appRoutes.LOGIN.route)
                }
            }
        } else {
            if (router.pathname === "/") {
                if (isBrowser) {
                    router.push(appRoutes.DASHBOARD.route)
                }
            }
        }
    }
}

export const userNotLoaded = (noUser, router) => {
    return noUser === undefined || (noUser && unprotectedRoutes.indexOf(router.pathname) === -1)
}

export const mobilePage = (router) => {
    return (
        isBrowser &&
        (mobileRoutes.indexOf(router.pathname) !== -1 || router.pathname.includes(appRoutes.PLAN_ACTIVATION.route))
    )
}

export const routeChangeStart = (router, action) => {
    return isBrowser() && router.events.on("routeChangeStart", action)
}

export const routeChangeComplete = (router, action) => {
    return isBrowser() && router.events.on("routeChangeComplete", action)
}

export const routeChangeError = (router, action) => {
    return isBrowser() && router.events.on("routeChangeError", action)
}

export const handlePageLoading = (pageLoading, setShowLoading) => {
    if (!pageLoading) {
        setTimeout(() => setShowLoading(pageLoading), 200)
    } else {
        setShowLoading(pageLoading)
    }
}

export const userHasPlan = (user) => {
    return user && user.purchasedPlans.length !== 0
}

export const redirectToLogin = () => {
    return {
        redirect: {
            permanent: false,
            destination: appRoutes.LOGIN.route
        },
        props: {}
    }
}

export const notVerifiedRedirect = () => {
    return {
        redirect: {
            permanent: false,
            destination: appRoutes.ONBOARDING.EMAIL_VERIFICATION.route
        },
        props: {}
    }
}

export const noCookieRedirect = async (context) => {
    const cookies = context.req.headers.cookie
    var parsedCookies = {}

    if (cookies) {
        parsedCookies = cookie.parse(cookies)
    }

    if (!parsedCookies.access_token) {
        return {
            redirect: {
                destination: appRoutes.LOGIN.route,
                permanent: false
            },
            props: {}
        }
    }

    return {
        props: {}
    }
}

export const tooManyAttemps = (res) => {
    if (res.status === 429) {
        handleSignOut(token)
        document.location = appRoutes.LOGIN.route
        return
    }
}

export const checkMaintenanceMode = async () => {
    const headers = getAppHeaders()
    const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/app/version`, {}, { headers })
    return res
}

export const instagramLink = (username) => {
    if (!username) {
        return ""
    }
    return `https://www.instagram.com/${username}/`
}
