import "../styles/bootstrap-custom.css"
import { GlobalStyle } from "../styles/global-style"
import Layout from "../components/layout/layout"
import Head from "next/head"
import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import LoadingScreen from "../components/screens/loading-screen"
import ReactTooltip from "react-tooltip"
import { UserProvider } from "../context/user-context"
import { routeChangeComplete, routeChangeError, routeChangeStart } from "../utils/routing"
import { tooltipBackground } from "../styles/dashboard"
import { isBrowser, isProductionNode } from "../components/protected-route"
import { token } from "../utils/auth"
import { unprotectedRoutes } from "../constants/private-routes"
import { appRoutes } from "../constants/app-routes"
import { UIProvider } from "../context/ui-context"
import { initializeApp } from "firebase/app"
import { getPerformance } from "firebase/performance"
import { firebaseConfig } from "../config/firebase-config"
import UnsupportedBrowser from "../components/unsupported-browser"

function MyApp({ Component, pageProps }) {
    const router = useRouter()
    const [pageLoading, setPageLoading] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [tokenValid, setTokenValid] = useState(null)
    const [browser, setBrowser] = useState("Unknown")

    const clientSide = isBrowser()

    useEffect(() => {
        if (clientSide && tokenValid === null) {
            token ? setTokenValid(1) : setTokenValid(0)
        }
        if (clientSide) {
            const detectBrowser = () => {
                if ((window.navigator.userAgent.indexOf("Opera") || window.navigator.userAgent.indexOf("OPR")) != -1) {
                    return "Opera"
                } else if (window.navigator.userAgent.indexOf("Chrome") != -1) {
                    return "Chrome"
                } else if (window.navigator.userAgent.indexOf("Safari") != -1) {
                    return "Safari"
                } else if (window.navigator.userAgent.indexOf("Firefox") != -1) {
                    return "Firefox"
                } else if (window.navigator.userAgent.indexOf("MSIE") != -1 || !!document.documentMode == true) {
                    return "Internet Explorer"
                } else {
                    return "Unknown"
                }
            }

            if (detectBrowser()) {
                setBrowser(detectBrowser())
            }
        }
    }, [clientSide])

    useEffect(() => {
        if (
            tokenValid === 0 &&
            unprotectedRoutes.indexOf(router.pathname) === -1 &&
            router.pathname !== appRoutes.LOGIN.route
        ) {
            router.replace(appRoutes.LOGIN.route)
        }
    }, [tokenValid])

    useEffect(() => {
        // disableReactDevTools()
        handlePageLoading()

        if (clientSide && browser && browser !== "Internet Explorer" && isProductionNode()) {
            const firebase = initializeApp(firebaseConfig)
            getPerformance(firebase)
        }
    }, [])

    const handlePageLoading = () => {
        if (!firstLoaded) {
            setTimeout(() => {
                setPageLoading(false)
                setFirstLoaded(true)
            }, 1200)
        } else {
            setPageLoading(false)
        }
    }

    useEffect(() => {
        const handleStart = () => setPageLoading(true)
        const handleComplete = () => handlePageLoading()

        routeChangeStart(router, handleStart)
        routeChangeComplete(router, handleComplete)
        routeChangeError(router, handleComplete)
    }, [router])

    useEffect(() => {
        clientSide && !pageLoading && ReactTooltip.hide()
    }, [router.pathname])

    if (clientSide) {
        !pageLoading && ReactTooltip.rebuild()
    }

    return (
        <>
            {tokenValid !== null && (
                <UserProvider>
                    <UIProvider>
                        <Layout>
                            <Head>
                                <title>JEDUNAVÝKON</title>
                                <meta
                                    name="viewport"
                                    content="width=device-width, initial-scale=1.0, maximum-scale=1"
                                />
                            </Head>
                            <GlobalStyle />
                            <Component {...pageProps} firstLoaded={firstLoaded} />
                        </Layout>
                        <ReactTooltip effect="solid" backgroundColor={tooltipBackground} globalEventOff="click" />
                    </UIProvider>
                </UserProvider>
            )}
            <LoadingScreen isLoading={!firstLoaded} />
            {browser && browser === "Internet Explorer" && <UnsupportedBrowser />}
        </>
    )
}

export default MyApp
