import React, { useState, createContext, useEffect, useContext } from "react"
import { UserContext } from "./user-context"

export const UIContext = createContext()

export const UIProvider = ({ children }) => {
    const [summaryIcons, setSummaryIcons] = useState(null)
    const [icons, setIcons] = useState(null)
    const [workoutImages, setWorkoutImages] = useState(null)
    // const [scrollPosition, setScrollPosition] = useState(0)

    const { currentUser, plansData } = useContext(UserContext)

    const getSummaryIcons = async () => {
        if (currentUser && currentUser.statistics) {
            let myIcons = []
            currentUser.statistics.forEach((stat, i) => {
                const blob = new Blob([stat.icon], { type: "image/svg+xml" })
                const url = URL.createObjectURL(blob)
                myIcons[i] = url
            })
            setSummaryIcons(myIcons)
        }
    }

    // const handleScroll = () => {
    //     const position = window.pageYOffset
    //     setScrollPosition(position)
    // }
    //
    // useEffect(() => {
    //     window.addEventListener("scroll", handleScroll, { passive: true })
    //
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll)
    //     }
    // }, [])

    const getIcons = async () => {
        let images = [
            "/assets/icons/smiley-green.svg",
            "/assets/icons/smiley-red.svg",
            "/assets/icons/trophy_icon_purple.svg",
            "/assets/icons/flash_white_icon.svg"
        ]
        let myIcons = {}
        myIcons.smiley = {}

        images.forEach((img, i) => {
            fetch(img)
                .then((resp) => resp.blob())
                .then((blob) => {
                    let url = URL.createObjectURL(blob)

                    if (i === 0) {
                        myIcons.smiley.green = url
                    }

                    if (i === 1) {
                        myIcons.smiley.red = url
                    }

                    if (i === 2) {
                        myIcons.trophy = url
                    }

                    if (i === 3) {
                        myIcons.flash_white = url
                    }
                })
                .catch(() => {})
        })

        let userAvatars = [
            "/assets/icons/avatar/jnv_avatar_1.png",
            "/assets/icons/avatar/jnv_avatar_2.png",
            "/assets/icons/avatar/jnv_avatar_3.png",
            "/assets/icons/avatar/jnv_avatar_4.png",
            "/assets/icons/avatar/jnv_avatar_5.png",
            "/assets/icons/avatar/jnv_avatar_6.png",
            "/assets/icons/avatar/jnv_avatar_7.png"
        ]

        let avatars = []
        userAvatars.forEach((img, i) => {
            fetch(img)
                .then((resp) => resp.blob())
                .then((blob) => {
                    let url = URL.createObjectURL(blob)
                    avatars[i + 1] = url
                })
                .catch(() => {})
        })

        myIcons.avatars = avatars

        fetch("/assets/JNV-video-loading.gif")
            .then((resp) => resp.blob())
            .then((blob) => {
                let url = URL.createObjectURL(blob)
                myIcons.videoLoading = url
            })

        setIcons(myIcons)
    }

    const getWorkoutImages = (plans) => {
        let originalImages = []
        if (plans && plans.purchasedPlans) {
            plans.purchasedPlans.forEach((plan) => {
                plan.workout_days.length > 0 &&
                    plan.workout_days.forEach((day) => originalImages.push(day.workout.image))
            })
        }
        setWorkoutImages(originalImages)
    }

    useEffect(() => {
        if (!icons) {
            getIcons()
        }
    }, [])

    useEffect(() => {
        if (currentUser) {
            if (!summaryIcons) {
                getSummaryIcons()
            }
        }
    }, [currentUser])

    useEffect(() => {
        if (
            plansData &&
            plansData.purchasedPlans &&
            plansData.purchasedPlans.length > 0 &&
            currentUser &&
            !workoutImages
        ) {
            getWorkoutImages(plansData)
        }
    }, [plansData, currentUser])

    return <UIContext.Provider value={{ summaryIcons, icons, workoutImages }}>{children}</UIContext.Provider>
}
