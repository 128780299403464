import styled, { css, keyframes } from "styled-components"
import { fullDashboardBreakpoint } from "../../../styles/dashboard"
import { breakpoint, color, errorColors, radius, sizes, transitionDuration, zIndex } from "../../../styles/global-style"

export const ErrorContainer = (props) => {
    return <Container>{props.children}</Container>
}

const animation = keyframes`
    0% {
        max-height: 0;
    }
    100% {
        max-height: 1000px;
    }
`

const Container = styled.div`
    animation: ${animation} 1s;
    overflow: hidden;
    max-height: 1000px;
    transition: height ${transitionDuration};

    z-index: ${zIndex.errorBanner};
    margin: -20px 20px 10px;
    width: calc(100% - 40px);
    max-width: 1140px;

    ${breakpoint(sizes.desktopS)} {
        margin-left: 100px;
        width: calc(100% - 120px);
    }

    ${breakpoint("1100px")} {
        margin-left: 172px;
        width: calc(100% - 192px);
    }

    ${breakpoint(fullDashboardBreakpoint)} {
        margin-left: 202px;
        width: calc(100% - 222px);
    }
`

const ErrorBanner = (props) => {
    const { type, bg, children, isActive } = props

    return (
        <Wrapper type={type} bg={bg} isActive={isActive}>
            <ErrorText>Upozornění: {children}</ErrorText>
        </Wrapper>
    )
}

export default ErrorBanner

const Wrapper = styled.div`
    padding: 11px 30px;
    background: ${(props) => (props.type ? errorColors[props.type] : props.bg)};
    border-radius: ${radius.main};
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    overflow: hidden;
    max-height: 100px;

    transition: all ${transitionDuration}, margin 0s, width 0s;

    img {
        padding: 2px;
        cursor: pointer;
    }

    ${({ isActive }) =>
        !isActive &&
        css`
            max-height: 0;
            padding: 0 30px;
            opacity: 0;
            margin-bottom: 0;
            margin-top: 0;
        `}
`

const ErrorText = styled.h6`
    color: ${color.ghost_white};
    font-weight: 400;
    margin-right: 1rem;
`
