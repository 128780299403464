import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import { appRoutes } from "../../../constants/app-routes"
import { breakpoint, color, gradient, radius, sizes, transitionDuration } from "../../../styles/global-style"
import { PurpleButton } from "../../ui/buttons"

const ProfileNavigation = () => {
    const router = useRouter()
    const [pathname, setPathname] = useState()
    const b2Ref = useRef()
    const b3Ref = useRef()
    const [buttonsWidth, setButtonsWidth] = useState([])
    const [activeButton, setActiveButton] = useState(1)
    const [loaded, setLoaded] = useState(false)

    const handleLink = (page) => {
        switch (page) {
            case "subscription":
                return router.push(appRoutes.SUBSCRIPTION.route)
            case "settings":
                return router.push(appRoutes.ACCOUNT_SETTINGS.route)

            default:
                return
        }
    }

    const handleActive = (page) => {
        return pathname === page
    }

    useEffect(() => {
        setPathname(router.pathname)
    }, [router])

    useEffect(() => {
        b2Ref && b3Ref && setButtonsWidth([b2Ref.current.offsetWidth, b3Ref.current.offsetWidth])

        if (router.pathname === appRoutes.SUBSCRIPTION.route) {
            setActiveButton(2)
        }
        if (router.pathname === appRoutes.ACCOUNT_SETTINGS.route) {
            setActiveButton(1)
        }
    }, [b2Ref, b3Ref, router])

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true)
            clearTimeout()
        }, 10)
    }, [])

    return (
        <>
            {buttonsWidth && (
                <Nav
                    loaded={loaded}
                    buttonsWidth={buttonsWidth}
                    activePage={activeButton}
                    isPathname={pathname !== undefined}
                >
                    <NavButton
                        isActive={handleActive(appRoutes.ACCOUNT_SETTINGS.route)}
                        onClick={() => handleLink("settings")}
                        ref={b2Ref}
                    >
                        Nastavení
                    </NavButton>
                    <NavButton
                        isActive={handleActive(appRoutes.SUBSCRIPTION.route)}
                        onClick={() => handleLink("subscription")}
                        ref={b3Ref}
                    >
                        Členství
                    </NavButton>
                </Nav>
            )}
        </>
    )
}

export default ProfileNavigation

const Nav = styled.div`
    display: flex;
    justify-content: flex-start;
    background: ${color.ghost_white};
    width: 100%;
    border-radius: ${radius.main};
    margin-right: 40px;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        border-radius: ${radius.main};
        width: ${(props) => props.buttonsWidth[props.activePage - 1]}px;
        height: 100%;
        top: 0;
        left: ${(props) =>
            props.buttonsWidth && props.activePage === 1 ? 0 : props.activePage === 2 && props.buttonsWidth[0]}px;
        background: ${gradient.main};
        z-index: 1;
        ${(props) =>
            props.isPathname &&
            props.loaded &&
            css`
                transition: left 0.2s, width 0.2s;
            `}
    }
    ${breakpoint(sizes.desktopS)} {
        width: calc(100% - 250px);
    }

    button {
        ${(props) =>
            props.loaded
                ? css`
                      transition: color ${transitionDuration};
                  `
                : css`
                      transition: all 0s;
                  `}
    }
`

const NavButton = styled(PurpleButton)`
    padding: 18px 30px;
    width: fit-content;
    margin: 0;
    background: transparent;
    z-index: 2;
    ${(props) =>
        !props.isActive &&
        css`
            color: ${color.charleston};
        `}
`
