import { createGlobalStyle } from "styled-components"

export const sizes = {
    min: "320px",
    mobile: "375px",
    tablet: "768px",
    desktopS: "1024px",
    desktopM: "1200px",
    desktopL: "1440px",
    desktopXL: "2560px"
}

export const breakpoint = (point) => {
    return `@media screen and (min-width: ${point})`
}

export const fontWeight = {
    normal: 400,
    semi_bold: 600,
    bold: 700
}

export const fontSize = {
    h1: "36px",
    h2: "28px",
    h3: "22px",
    h4: "16px",
    h5: "14px",
    h6: "12px",
    p: "14px",
    button: "14px"
}

export const lineHeight = {
    h1: "54px",
    h2: "24px",
    h3: "33px",
    h4: "24px",
    h5: "21px",
    h6: "18px",
    p: "22px",
    button: "21px"
}

export const color = {
    // primary
    dark: "#100C17",
    white: "#ffffff",

    // secondary
    lavender: "#9D5BE8",
    floral: "#BC77EC",
    vodka: "#C8B6FF",
    pale_lavender: "#E7C6FF",

    // extra
    ghost_white: "#FAF8FF",
    magnolia: "#F4F0FF",
    languid: "#D3D1DD",
    philippine: "#B4B3BB",
    charleston: "#28282A",
    red: "#FC5276",
    placeholderGrey: "#8B8B8B"
}

export const gradient = {
    main: "linear-gradient(90deg, #9D5BE8 0%, #BC77EC 100%)",
    light: "linear-gradient(90deg, #C8B6FF 0%, #E7C6FF 100%)",
    soft: "linear-gradient(90deg, #B4B3BB 0%, #D3D1DD 100%)"
}

export const radius = {
    main: "10px",
    small: "4px",
    large: "26px"
}

export const errorColors = {
    error: "#FC5276",
    warning: "#F0AD4E",
    success: "#2FD991"
}

export const zIndex = {
    getAppPopup: 9999,
    offlineModal: 15,
    workoutResultsPopup: 14,
    defaultPopup: 14,
    errorBanner: 14,
    loadingModal: 16,
    unsupportedBrowserModal: 17,
    sidebar: 10,
    workoutLoading: 15,
    workoutBlurredEdge: 13,
    endingWorkoutBanner: 15
}

export const transitionDuration = "0.3s"

export const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: Helvetica;
        src: url("/fonts/Helvetica.ttf");
        font-style: normal;
        font-weight: 400;
        font-display: swap;
    }

    @font-face {
        font-family: "Basier Square";
        src: url("/fonts/BasierSquare-Regular.otf");
        font-style: normal;
        font-weight: 400;
        font-display: swap;
    }

    @font-face {
        font-family: "Basier Square";
        src: url("/fonts/BasierSquare-Medium.otf");
        font-style: normal;
        font-weight: 500;
        font-display: swap;
    }

    @font-face {
        font-family: "Basier Square";
        src: url("/fonts/BasierSquare-SemiBold.otf");
        font-style: normal;
        font-weight: 600;
        font-display: swap;
    }

    html {
        scroll-behavior: auto;
        font-size: 16px;
        overflow-y: scroll;
    }

    html,
    body {
        padding: 0;
        margin: 0;
        font-family: sans-serif;
        min-height: 100vh;
        min-width: ${sizes.min};
    }

    #__next {
        min-height: 100vh;
    }

    .__react_component_tooltip {
        border: 0 !important;
    }

    a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }

    * {
        box-sizing: border-box;
        font-family: "Basier Square", sans-serif;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        margin: 0;
        padding: 0;
        font-weight: ${fontWeight.semi_bold};
    }

    .fit-content {
        width: fit-content;
        width: -webkit-fit-content;
    }

    button {
        cursor: pointer;
        outline: none;
        border: 1px solid transparent;
        transition: all 0.2s;
    }

    input {
        outline: none;
        border: 1px solid ${color.charleston};
        font-weight: 600;
        font-size: ${fontSize.h6};
        line-height: ${lineHeight.h6};
        &:-webkit-autofill,
        &:-webkit-autofill:focus {
            transition: background-color 600000s 0s, color 600000s 0s;
        }
    }

    h1, h2, h3, h4 {
        font-weight: 600;
        margin-top: 0.2em;
        margin-bottom: 0.2em;
    }

    h1, h2, h3, h4, h5, h6, p {
        color: ${color.charleston};
    }

    h1 {
        margin-top: 0;
        margin-bottom: 0;
        
        font-size: 28px;
        line-height: 42px;
    }

    h2 {
        font-size: 24px;
        line-height: 36px;
    }

    h3 {
        font-size: 20px;
        line-height: 30px;
    }

    h4 {
        font-size: 16px;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 0;
    }

    h5 {
        font-size: 14px;
        line-height: 21px;
    }

    h6 {
        font-size: 12px;
        line-height: 18px;
    }

    p {
        font-size: 14px;
        line-height: 22px;
        margin-top: 0.2em;
        margin-bottom: 0.2em;
        a {
        color: ${color.floral};
        }
    }

    .smooth-scroll {
        scroll-behavior: smooth;
    }

    .react-calendar {
        border-radius: ${radius.main};
        overflow: hidden;
        padding: 5px;
        border: 0;
        background: ${color.ghost_white};

        &__tile {
            border-radius: ${radius.small};
            transition: background-color 0.2s, color 0.2s;
            font-size: 14px;
            line-height: 20px;

            &:enabled:hover {
                background-color: ${color.lavender};
                color: ${color.white}
            }
            &--now {
                background: transparent;
                &:enabled:hover, &:enabled:focus {
                    background: #e6e6e6;
                }
            }
            &--active {
                background: ${color.lavender};
                &:enabled:hover, &:enabled:focus {
                    background: ${color.lavender};
                }
            }

            &:disabled {
                background: transparent;
                color: #cccccc;
                opacity: 0.8;

                &.react-calendar__month-view__days__day--weekend {
                    color: #ffb3b3;
                }
            }
            
        }

        &__navigation__arrow {
            border-radius: ${radius.main};
        }

        &__navigation button:enabled:hover, &__navigation button:enabled:focus {
                background: transparent;
            }

        &__month-view__days__day {
            color: ${color.charleston};

            &--neighboringMonth {
            color: ${color.charleston};
        }
        }
    }

    .react-calendar__tile--active {
        color: white;
    }

    .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
        background: ${color.lavender};
    }

    .react-calendar__navigation__label {
        background: transparent !important;
        cursor: default;
    }
    .react-calendar__navigation {
        button:enabled:focus {
            background: transparent;
        }
        * {
            color: ${color.charleston} !important;
            font-size: 16px;
            line-height: 30px;
        }
    }
    .react-calendar__month-view__weekdays__weekday {
        color: ${color.charleston} !important;
        * {
            text-decoration: none;
        }
    }
    .react-calendar__navigation__prev2-button {
        z-index: -1;
    }
    .react-calendar__navigation__next2-button {
        z-index: -1;
    }

`

export const numChangingVideoDuration = 200

export const minUnusedHeight = "620px"

export const workoutVideoTransition = `${transitionDuration} ease-in-out`

export const publicProfileBreakpoint = 1300
