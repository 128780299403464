const TimerIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8 8.8H7.2V3.2H8.8V7.2H12.8V8.8ZM1.6 14.4H14.4V1.6H1.6V14.4ZM0 16H16V0H0V16Z"
                fill="white"
            />
        </svg>
    )
}

export default TimerIcon
