import { useLayoutEffect, useState } from "react"
import { alphabet } from "../constants/alphabet"
import { isBrowser } from "../components/protected-route"
import moment from "moment"
import { DATE_FORMATS } from "../constants/formats"

export const disabledChecker = (props, varName) => {
    return !props.touched[varName] || props.values[varName] == props.initialValues[varName]
}

export const phoneRegExp = new RegExp(/^\+?\d{9,13}$/)

export const checkPhoneNumber = (inp) => {
    return phoneRegExp.test(inp)
}

export const symbolOrder = (index) => {
    return alphabet[index]
}

export const capitalFirstLetter = (word) => {
    if (typeof word === "string") {
        const toCapitalize = word.charAt(0)
        return word.replace(word[0], toCapitalize.toUpperCase())
    }

    return word
}

export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0])
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight])
        }
        window.addEventListener("resize", updateSize)
        updateSize()
        return () => window.removeEventListener("resize", updateSize)
    }, [])
    return size
}

// returns number with space after 3 digits
export const prettyNumber = (num) => {
    if (!num) return 0

    num = num.toString().replace(".", ",")

    if (num.length > 6) {
        if (num.includes(",")) {
            var numParts = num.split(",")
            return `${numParts[0].substring(0, numParts[0].length - 6)} ${numParts[0].substring(
                numParts[0].length - 6,
                numParts[0].length - 3
            )} ${numParts[0].substring(numParts[0].length - 3, numParts[0].length)},${numParts[1]}`
        }
        return `${num.substring(0, num.length - 6)} ${num.substring(num.length - 6, num.length - 3)} ${num.substring(
            num.length - 3,
            num.length
        )}`
    }

    if (num.length > 3) {
        if (num.includes(",")) {
            var numParts = num.split(",")
            return `${numParts[0].substring(0, numParts[0].length - 3)} ${numParts[0].substring(
                numParts[0].length - 3,
                numParts[0].length
            )},${numParts[1]}`
        }
        return `${num.substring(0, num.length - 3)} ${num.substring(num.length - 3, num.length)}`
    }

    return num
}

export const valueWithUnits = (value, units) => {
    if (units) {
        return `${value} ${units}`
    }
    return value
}

export const cutZeroDate = (inp) => {
    return inp.toString().charAt(0) === "0" ? inp.toString().replace("0", "") : inp
}

export const getSlugFromDate = (date) => {
    let month = (date.getMonth() + 1).toString().length !== 2 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    let day = date.getDate().toString().length !== 2 ? `0${date.getDate()}` : date.getDate()
    return `${date.getFullYear()}-${month}-${day}`
}

export const getDateFromSlug = (slug) => {
    return new Date(slug)
}

export const getCZDateFromSlug = (slug) => {
    var date = getDateFromSlug(slug)
    return new moment(date).format(DATE_FORMATS.only_date)
}

export const getCZDateWithoutYearFromSlug = (slug) => {
    var date = getDateFromSlug(slug)
    date = new Date(date)

    return `${cutZeroDate(date.getDate())}.${cutZeroDate(date.getMonth() + 1)}.`
}

export const hideCardNumber = (num) => {
    num = num.toString()
    var hiddenPart = num.substring(2, num.length - 4)
    var replacedPart = "*".repeat(hiddenPart.length)
    num.replace(hiddenPart, replacedPart)
    return num.replace(hiddenPart, replacedPart)
}

export const getUserAvatar = (id) => {
    if (id) {
        return `/assets/icons/avatar/jnv_avatar_${id}.png`
    }

    return `/assets/icons/avatar/jnv_avatar_1.png`
}

export const disableReactDevTools = () => {
    if (isBrowser() && process.env.NODE_ENV === "production") {
        if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== "object") {
            return
        }

        for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
            if (prop === "renderers") {
                window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map()
                continue
            }
            typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === "function" ? Function.prototype : null
        }
    }
}

export const planType = (length, plan) => {
    if (length) {
        switch (length) {
            case 1:
                return "Měsíční"
            case 3:
                return "Čtvrtletní"
            case 6:
                return "Půlroční"
            case 12:
                return "Roční"
            default:
                return `${length}měsíční`
        }
    } else {
        if (plan.source === "free") {
            return "Zdarma"
        }
    }
}
