import styled from "styled-components"
import { NoScroll } from "../../styles/dashboard"
import { transitionDuration } from "../../styles/global-style"
import { MiniTransparentButton } from "../ui/buttons"
import { LightParagraph } from "../ui/elements"
import { Popup } from "../ui/popups"
import { AppleAppStoreIcon } from "./badges/apple"
import { GooglePlayIcon } from "./badges/google"

const GetAppPopup = () => {
    return (
        <>
            <NoScroll />
            <Popup isAppPopup={true}>
                <h2 style={{ maxWidth: "200px" }}>Pro mobily máme parádní appku.</h2>
                <LightParagraph marginBottom={10} marginTop={10} style={{ maxWidth: "315px" }}>
                    Až se v naší webové aplikaci staneš členkou, nezapomeň si stáhnout mobilní aplikaci.
                </LightParagraph>
                <ButtonsContainer>
                    <MiniTransparentButton
                        href={"https://apps.apple.com/cz/app/jedunavykon/id1609249708"}
                        target={"_blank"}
                    >
                        <AppleAppStoreIcon />
                    </MiniTransparentButton>
                    <MiniTransparentButton
                        href={"https://play.google.com/store/apps/details?id=com.jedunavykon2"}
                        target={"_blank"}
                        style={{ marginTop: 0 }}
                    >
                        <GooglePlayIcon />
                    </MiniTransparentButton>
                </ButtonsContainer>
                <Graphics src="/assets/get-app-graphics.png" alt="Aplikace" />
            </Popup>
        </>
    )
}

export default GetAppPopup

const ButtonsContainer = styled.div`
    button {
        display: block;
        margin: 20px auto;
    }

    transition: margin-bottom ${transitionDuration};

    @media screen and (max-height: 850px) {
        margin-bottom: 50px;
    }
`

const Graphics = styled.img`
    width: 320px;
    margin-top: auto;
`
