import { css } from "styled-components"
import { color } from "./global-style"

export const fullDashboardBreakpoint = "1370px"

export const prettyScroller = css`
    ::-webkit-scrollbar {
        width: 10px;
        height: 100px;
    }

    ::-webkit-scrollbar-track {
        background: ${color.white};
    }

    ::-webkit-scrollbar-thumb {
        background: ${color.languid};
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${color.philippine};
    }

    scrollbar-color: ${color.languid} ${color.white};
    scrollbar-width: thin;
`

export const noScroller = css`
    ::-webkit-scrollbar {
        display: none;
    }
`

export const tooltipBackground = "rgba(0, 0 , 0, .9)"

export const resizeTransition = css`
    transition: all 0.3s;
`

const noScrolLStyle = css`
    body {
        overflow: hidden;
    }
`

export const NoScroll = () => {
    return <style>{noScrolLStyle}</style>
}
