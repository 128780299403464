const CloseIcon = ({ color }) => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.99999 5L10 8L8.00001 10L5 7L1.99999 10L0 8L3.00001 5L0 2L1.99999 0L5 3L8.00001 0L10 2L6.99999 5Z"
                fill={color || "white"}
            />
        </svg>
    )
}

export default CloseIcon
