import styled, { css } from "styled-components"
import { breakpoint, color, gradient, radius, sizes } from "../../styles/global-style"
import { SmallHeading } from "../ui/elements"
import { PillButton } from "../ui/buttons"
import { useRouter } from "next/router"
import { appRoutes, withProfileHeader } from "../../constants/app-routes"
import { capitalFirstLetter, getDateFromSlug, getSlugFromDate, getUserAvatar } from "../../utils"
import { announcements, weekDays } from "../../constants/announcements"
import { fullDashboardBreakpoint } from "../../styles/dashboard"
import { useContext, useEffect, useState } from "react"
import { UserContext } from "../../context/user-context"
import ProfileNavigation from "../dashboard/profile/navigation"
import CalendarPopup from "../dashboard/calendar-popup"
import CalendarIcon from "../header/calendar-icon"
import ErrorBanner, { ErrorContainer } from "../ui/error-fields/error-banner"
import { UIContext } from "../../context/ui-context"

const Header = ({ fullHeader }) => {
    const [isProfile, setIsProfile] = useState(false)
    const router = useRouter()
    const { currentUser, plansData, handleDateChange, changePlan, errors } = useContext(UserContext)

    const { icons } = useContext(UIContext)

    const [showCalendar, setShowCalendar] = useState(false)

    const handleDay = (day) => {
        var todayDate = new Date()

        if (day === "today") {
            day = getSlugFromDate(todayDate)
        }
        if (day === "tomorrow") {
            todayDate.setDate(todayDate.getDate() + 1)
            day = getSlugFromDate(todayDate)
        }
        if (day === "dayAfterTomorrow") {
            todayDate.setDate(todayDate.getDate() + 2)
            day = getSlugFromDate(todayDate)
        }

        document.documentElement.className = "smooth-scroll"

        const timeout = setTimeout(() => {
            handleDateChange(day)
            window.scrollTo(0, 0)
            document.documentElement.className = document.documentElement.className.replace("smooth-scroll", "")
            clearTimeout(timeout)
        }, 50)
    }

    const handleChangePlan = (plan) => {
        document.documentElement.className = "smooth-scroll"

        const timeout = setTimeout(() => {
            changePlan(plan.plan_id)
            window.scrollTo(0, 0)
            document.documentElement.className = document.documentElement.className.replace("smooth-scroll", "")
            clearTimeout(timeout)
        }, 50)
    }
    //
    // const handleMorePlans = () => {
    //     router.push(appRoutes.SUBSCRIPTION_SELECT.route)
    // }

    const handleProfile = () => {
        router.push(appRoutes.STATISTICS.route)
    }

    const activeDay = (inp) => {
        if (plansData && plansData.activeDay) {
            var whatDay = plansData.activeDay
            var wantedDay = new Date()

            if (inp === "today") {
                wantedDay = getSlugFromDate(wantedDay)
            }
            if (inp === "tomorrow") {
                wantedDay.setDate(wantedDay.getDate() + 1)
                wantedDay = getSlugFromDate(wantedDay)
            }
            if (inp === "dayAfterTomorrow") {
                wantedDay.setDate(wantedDay.getDate() + 2)
                wantedDay = getSlugFromDate(wantedDay)
            }
            if (inp === "calendar") {
                var tomorrow = new Date()
                tomorrow.setDate(wantedDay.getDate() + 1)

                var dayAfter = new Date()
                dayAfter.setDate(wantedDay.getDate() + 2)

                return (
                    whatDay !== getSlugFromDate(tomorrow) &&
                    whatDay !== getSlugFromDate(wantedDay) &&
                    whatDay !== getSlugFromDate(dayAfter)
                )
            }
            return whatDay === wantedDay
        }
    }

    const isActivePlan = (inp) => {
        if (plansData && plansData.purchasedPlans) {
            if (plansData.purchasedPlans.length > 0) {
                return plansData.activePlan.plan_name === inp
            }
        }
    }

    const disabledDays = (date) => {
        if (plansData && plansData.allAvailableDays) {
            return plansData.allAvailableDays.indexOf(getSlugFromDate(date)) === -1
        }
    }

    const handleCalendarChange = (data) => {
        setShowCalendar(false)
        handleDay(getSlugFromDate(data))
    }

    const getDayAfterTomorrowInWeek = () => {
        let day = new Date().getDay() + 2
        if (day < 7) {
            return weekDays[day]
        }
        return weekDays[day - 7]
    }

    useEffect(() => {
        withProfileHeader.indexOf(router.pathname) !== -1 ? setIsProfile(true) : setIsProfile(false)
    }, [router])

    return (
        <>
            <Wrapper>
                <UserIcon className="user-icon">
                    <UserIconContainer>
                        <h4>{currentUser.first_name || currentUser.name}</h4>
                        {plansData && plansData.workoutDay && plansData.workoutDay.completed && (
                            <UserStatus>{announcements.todayFinished}</UserStatus>
                        )}
                    </UserIconContainer>
                    <Avatar bg={"#B6BAEA"} onClick={handleProfile}>
                        <AvatarImg
                            bg={currentUser.avatar_color ? currentUser.avatar_color : ""}
                            src={
                                currentUser.image ||
                                (icons && icons.avatars[currentUser.avatar_id]) ||
                                getUserAvatar(currentUser.avatar_id)
                            }
                            width={30}
                            height={30}
                            alt="ikona"
                        />
                    </Avatar>
                </UserIcon>
                {fullHeader && (
                    <>
                        <Days>
                            <SmallHeading style={{ paddingBottom: "4px" }}>{announcements.availableDays}</SmallHeading>
                            <DaysButtons>
                                <PillButton isDisabled={!activeDay("today")} onClick={() => handleDay("today")}>
                                    Dnes
                                </PillButton>
                                <PillButton isDisabled={!activeDay("tomorrow")} onClick={() => handleDay("tomorrow")}>
                                    Zítra
                                </PillButton>
                                <PillButton
                                    isDisabled={!activeDay("dayAfterTomorrow")}
                                    onClick={() => handleDay("dayAfterTomorrow")}
                                >
                                    {plansData && getDayAfterTomorrowInWeek()}
                                </PillButton>
                                <CalendarButton
                                    isDisabled={!activeDay("calendar")}
                                    onClick={() => setShowCalendar(true)}
                                >
                                    <CalendarIcon isDisabled={!activeDay("calendar")} />
                                </CalendarButton>
                            </DaysButtons>
                        </Days>
                        <UserPlans
                            multiplePlans={plansData && plansData.purchasedPlans && plansData.purchasedPlans.length > 0}
                        >
                            {plansData && plansData.purchasedPlans && plansData.purchasedPlans.length > 0 && (
                                <>
                                    <SmallHeading style={{ paddingBottom: "4px" }}>
                                        {announcements.activePlans}
                                    </SmallHeading>
                                    <DaysButtons>
                                        {plansData.purchasedPlans.map((plan, i) => (
                                            <PillButton
                                                key={i}
                                                isDisabled={!isActivePlan(plan.plan_name)}
                                                onClick={() => handleChangePlan(plan)}
                                            >
                                                {capitalFirstLetter(plan.plan_name)}
                                            </PillButton>
                                        ))}
                                    </DaysButtons>
                                </>
                            )}
                        </UserPlans>
                    </>
                )}
                {isProfile && <ProfileNavigation />}
                {router.pathname === appRoutes.STATISTICS.route && <h1>{appRoutes.STATISTICS.name}</h1>}
                {router.pathname === appRoutes.COMMUNITY.route && <h1>{appRoutes.COMMUNITY.name}</h1>}
            </Wrapper>
            {showCalendar && (
                <CalendarPopup
                    calendarValue={getDateFromSlug(router.query.day)}
                    handleCalendarChange={handleCalendarChange}
                    disabledDays={disabledDays}
                    setShowCalendar={setShowCalendar}
                />
            )}
            {errors && errors.length > 0 && (
                <ErrorContainer>
                    {errors.map((error, i) => (
                        <ErrorBanner key={error.id} type="warning" isActive={error.active} errorIndex={i}>
                            {error.text}
                        </ErrorBanner>
                    ))}
                </ErrorContainer>
            )}
        </>
    )
}

export default Header

const Wrapper = styled.header`
    width: 100%;
    max-width: 1378px;
    padding: 40px 20px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    height: 140px;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ${breakpoint(sizes.desktopS)} {
        padding: 40px 0 40px;
    }

    ${breakpoint(sizes.desktopS)} {
        padding-left: calc(100px + 1rem);
    }

    ${breakpoint("1100px")} {
        padding-left: calc(172px + 1rem);
    }

    ${breakpoint(fullDashboardBreakpoint)} {
        padding-left: calc(202px + 1rem);
    }
`

const Days = styled.div`
    order: 1;
    border-right: 1px solid ${color.magnolia};

    ${breakpoint(sizes.desktopS)} {
        margin-top: 0;
    }
`

const DaysButtons = styled.div`
    display: flex;
    align-items: center;

    button {
        transition: all 0s;
        margin-right: 20px;
        height: 30px;
    }
`

const UserIconContainer = styled.div`
    display: none;

    ${breakpoint("1200px")} {
        display: block;
        text-align: right;
    }
`

const UserIcon = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    border-radius: ${radius.main};
    order: 3;
    margin-left: auto;

    h4 {
        margin: 0;
    }

    ${breakpoint("900px")} {
        margin-left: 0;
    }

    ${breakpoint(sizes.desktopS)} {
        margin-right: 20px;
    }

    ${breakpoint("1200px")} {
        background: ${color.ghost_white};
        width: 250px;
        width: min(100%, 250px);
        min-width: 210px;
        height: 60px;
        flex-shrink: 0;
    }
`

const Avatar = styled.div`
    width: 40px;
    height: 40px;
    border-radius: ${radius.main};
    overflow: hidden;
    z-index: 2;
    cursor: pointer;
    margin: 10px;
`

const AvatarImg = styled.img`
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    object-fit: cover;

    ${({ bg }) =>
        bg &&
        css`
            background: ${bg};
        `}
`

const CalendarButton = styled.button`
    border: 1px solid ${color.magnolia};
    background: ${(props) => (props.isDisabled ? color.ghost_white : gradient.main)};
    ${(props) =>
        !props.isDisabled &&
        css`
            color: white;
        `}
    padding: 6px;
    border-radius: ${radius.small};
    line-height: 16px;
`

const UserPlans = styled.div`
    ${(props) => !props.multiplePlans && "display: flex;"}
    align-items: center;
    order: 2;
    margin-top: 20px;
    margin: 0 auto 0 20px;
    flex-shrink: 0;
`
//
// const ActivePlan = styled.div`
//     width: fit-content;
//     height: 60px;
//     background: ${gradient.light};
//     padding: 4px 25px 5px 10px;
//     border-radius: ${radius.main};
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     margin-right: 20px;
//
//     h3 {
//         color: white;
//         margin: 0;
//     }
//     h5 {
//         font-size: 14px;
//         line-height: 21px;
//         color: ${color.lavender};
//         margin: 0;
//     }
// `
//
// const OtherPlans = styled.button`
//     height: 60px;
//     width: 60px;
//     background: ${color.white};
//     border-radius: ${radius.main};
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border: 1px dashed ${color.magnolia};
// `

const UserStatus = styled.h4`
    color: ${color.floral};
    font-weight: 400;
    margin-top: -3px !important;
    overflow: hidden;
`
