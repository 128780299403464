import { useEffect } from "react"
import ReactTooltip from "react-tooltip"
import styled, { css, keyframes } from "styled-components"
import { breakpoint, color, radius, sizes, transitionDuration, zIndex } from "../../../styles/global-style"

export const Popup = (props) => {
    useEffect(() => {
        ReactTooltip.rebuild()
    }, [])
    return (
        <Wrapper isAppPopup={props.isAppPopup} minHeight={props.minHeight}>
            <Content minHeight={props.minHeight}>
                <Inner
                    isAppPopup={props.isAppPopup}
                    minHeight={props.minHeight}
                    width={props.width}
                    bg={props.bg}
                    paddingBottom={props.paddingBottom}
                    paddingTop={props.paddingTop}
                    paddingLeft={props.paddingLeft}
                    paddingRight={props.paddingRight}
                >
                    {props.children}
                </Inner>
            </Content>
        </Wrapper>
    )
}

const revealPopup = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
`

const Wrapper = styled.div`
    position: fixed;
    min-width: 100vw;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background: rgba(16, 12, 23, 0.6);
    z-index: ${(props) => (props.isAppPopup ? zIndex.getAppPopup : props.zIndex ? props.zIndex : zIndex.defaultPopup)};
    top: 0;
    left: 0;
    bottom: 0;
    animation: ${revealPopup} 0.2s ease;
    ${(props) =>
        props.isAppPopup &&
        css`
            background: linear-gradient(90deg, #c8b6ff 0%, #e7c6ff 100%);

            ${breakpoint(sizes.tablet)} {
                display: none;
            }
        `}
`

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    ${(props) =>
        props.minHeight &&
        css`
            @media screen and (max-height: ${props.minHeight}px) {
                overflow: auto;
            }
        `}
`

const Inner = styled.div`
    background: ${(props) => props.bg || color.ghost_white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 70px 100px;
    z-index: 200;
    opacity: 1;
    border-radius: ${radius.main};
    text-align: center;
    width: 510px;
    ${(props) =>
        props.isAppPopup &&
        css`
            width: 100%;
            height: 100%;
            min-width: 320px;
            padding: 70px 0 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            border-radius: 0;
            transition: height ${transitionDuration};

            ${breakpoint("500px")} {
                padding: 70px 0 0;
                width: 80%;
                border-radius: ${radius.main};

                @media screen and (min-height: 840px) {
                    height: 840px;
                }
            }
        `}
    ${(props) =>
        props.minHeight &&
        css`
            min-height: ${props.minHeight}px;
            @media screen and (max-height: ${props.minHeight}px) {
                transform: translate(-50%, 0);
                top: 5%;
            }
        `}
    ${(props) =>
        props.width &&
        css`
            width: ${props.width}px;
            padding: 70px calc((${props.width}px - 510px) / 2 + 100px);
        `}
    ${(props) =>
        props.paddingTop &&
        css`
            padding-top: ${props.paddingTop}px;
        `}
    ${(props) =>
        props.paddingBottom &&
        css`
            padding-bottom: ${props.paddingBottom}px;
        `}
    ${(props) =>
        props.paddingRight &&
        css`
            padding-right: ${props.paddingRight}px;
        `}
    ${(props) =>
        props.paddingLeft &&
        css`
            padding-left: ${props.paddingLeft}px;
        `}
`
