export const appRoutes = {
    WELCOME_SCREEN: {
        name: "Jedu na výkon",
        route: "/"
    },
    LOGIN: {
        name: "Přihlášení",
        route: "/prihlaseni"
    },
    FORGOTTEN_PASSWORD: {
        name: "Zapomenuté heslo",
        route: "/zapomenute-heslo"
    },
    NEW_PASSWORD: {
        name: "Nové heslo",
        route: "/nove-heslo"
    },
    ONBOARDING: {
        REGISTRATION: {
            name: "Registrace",
            route: "/onboarding/registrace"
        },
        EMAIL_VERIFICATION: {
            name: "Ověření emailu",
            route: "/onboarding/overeni-emailu"
        },
        YOUR_PROFILE: {
            name: "Tvůj profil",
            route: "/onboarding/tvuj-profil"
        },
        REGISTRATION_SUCCESFULL: {
            name: "Úspěšná registrace",
            route: "/onboarding/uspesna-registrace"
        }
    },
    TUTORIAL: {
        name: "Tutoriál",
        route: "/tutorial"
    },
    SUCCESSFUL_LOGIN: {
        name: "Úspěšné přihlášení",
        route: "/uspesne-prihlaseni"
    },
    SUBSCRIPTION_SELECT: {
        name: "Výběr členství",
        route: "/vyber-clenstvi"
    },
    NO_PLAN: {
        name: "Tréninkový plán",
        route: "/treninkovy-plan"
    },
    PLAN_ACTIVATION: {
        name: "Aktivace členství",
        route: "/platba"
    },
    PAYMENT: {
        SUCCESSFUL: {
            name: "Úspěšná platba",
            route: "/uspesna-platba"
        },
        UNSUCCESSFUL: {
            name: "Neúspěšná platba",
            route: "/neuspesna-platba"
        }
    },
    DASHBOARD: {
        name: "Dashboard",
        route: "/dashboard"
    },
    WORKOUT_PAGE: {
        name: "Detail workoutu",
        route: "/workout",
        ACTIVE_WORKOUT: {
            name: "Aktivní workout",
            route: "/workout/[workoutId]",
            RESULTS: {
                name: "Výsledky workoutu",
                route: "/workout/[workoutId]/vysledky"
            },
            COMMENTS: {
                name: "Komentáře workoutu",
                route: "/workout/[workoutId]/komentare"
            }
        }
    },
    RESULT_INPUT: {
        name: "Zaznamenání výsledku",
        route: "/zaznamenani-vysledku"
    },
    ACCOUNT_SETTINGS: {
        name: "Nastavení účtu",
        route: "/nastaveni"
    },
    WORKOUT_RESULT: {
        name: "Výsledek workoutu",
        route: "/vysledek-workoutu"
    },
    TRAINING_HISTORY: {
        name: "Historie tréninků",
        route: "/historie-treninku"
    },
    STATISTICS: {
        name: "Statistiky",
        route: "/statistiky"
    },
    SUPPORT: {
        name: "Podpora",
        route: "/podpora"
    },
    SUBSCRIPTION: {
        name: "Členství",
        route: "/clenstvi"
    },
    MOBILE_REGISTRATION_SUCCESSFUL: {
        name: "Úspěšná registrace",
        route: "/uspesna-mobilni-registrace"
    },
    ACCOUNT_TRANSFER: {
        name: "Převod účtu",
        route: "/prevod-uctu"
    },
    MAINTENANCE: {
        name: "Údržba",
        route: "/udrzba"
    },
    COMMUNITY: {
        name: "Komunita",
        route: "/komunita"
    },
    PUBLIC_PROFILE: {
        name: "Profil",
        route: "/komunita/[id]"
    }
}

export const mobileRoutes = [
    appRoutes.WELCOME_SCREEN.route,
    appRoutes.LOGIN.route,
    appRoutes.FORGOTTEN_PASSWORD.route,
    appRoutes.NEW_PASSWORD.route,
    appRoutes.ONBOARDING.REGISTRATION.route,
    appRoutes.ONBOARDING.EMAIL_VERIFICATION.route,
    appRoutes.ONBOARDING.YOUR_PROFILE.route,
    appRoutes.ONBOARDING.REGISTRATION_SUCCESFULL.route,
    appRoutes.TUTORIAL.route,
    appRoutes.SUCCESSFUL_LOGIN.route,
    appRoutes.SUBSCRIPTION_SELECT.route,
    appRoutes.NO_PLAN.route,
    appRoutes.PLAN_ACTIVATION.route,
    appRoutes.PAYMENT.SUCCESSFUL.route,
    appRoutes.PAYMENT.UNSUCCESSFUL.route,
    appRoutes.MOBILE_REGISTRATION_SUCCESSFUL.route,
    appRoutes.ACCOUNT_TRANSFER.route,
    appRoutes.MAINTENANCE.route
]

export const withSidebarRoutes = [
    appRoutes.DASHBOARD.route,
    appRoutes.STATISTICS.route,
    appRoutes.ACCOUNT_SETTINGS.route,
    appRoutes.SUBSCRIPTION.route,
    appRoutes.COMMUNITY.route
]

export const withProfileHeader = [appRoutes.ACCOUNT_SETTINGS.route, appRoutes.SUBSCRIPTION.route]
