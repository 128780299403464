import { useRouter } from "next/router"
import React, { useState, createContext, useEffect } from "react"
import { getSlugFromDate } from "../utils"
import { getCookie, token } from "../utils/auth"
import {
    getBanners,
    getPersonalRecords,
    fetchPublicProfile,
    getUserProfile,
    getUserStatistics,
    getUserSubscriptions
} from "../utils/user"
import { getAvailableWorkouts } from "../utils/workout"
import { appRoutes } from "../constants/app-routes"
import { getBillingInfo } from "../utils/payment"
import { unprotectedRoutes } from "../constants/private-routes"
import { isBrowser, isProductionNode } from "../components/protected-route"
import cookieconsentConfig from "../components/cookie/cookieconsent-config"
import ReactGA from "react-ga"
import { initializeApp } from "firebase/app"
import { getPerformance } from "firebase/performance"
import { firebaseConfig } from "../config/firebase-config"
import { checkMaintenanceMode } from "../utils/routing"

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [userRecords, setUserRecords] = useState(null)
    const [plansData, setPlansData] = useState(null)
    const [emailVerified, setEmailVerified] = useState(null)
    const [banners, setBanners] = useState(null)
    const [errors, setErrors] = useState(null)
    const [billingInfo, setBillingInfo] = useState(null)
    const [justCompletedProfile, setJustCompletedProfile] = useState(false)
    const [consentCookie, setConsentCookie] = useState(null)
    const [cookieChecked, setCookieChecked] = useState(false)
    const [maintenanceMode, setMaintenanceMode] = useState(null)
    const [subscriptions, setSubscriptions] = useState(null)
    const [publicProfiles, setPublicProfiles] = useState(null)
    const [activePublicProfile, setActivePublicProfile] = useState(null)
    const [communitySearch, setCommunitySearch] = useState({ text: "", searchFavorite: false })

    const router = useRouter()

    const clientSide = isBrowser()

    const fetchUser = async () => {
        const res = await getUserProfile()

        if (res.status === 200) {
            if (currentUser) {
                setCurrentUser({ ...currentUser, ...res.data.data })
            } else {
                setCurrentUser(res.data.data)
            }

            if (res.data.data.active_plans && res.data.data.active_plans.length > 0) {
                let newErrors = []
                res.data.data.active_plans.forEach((p, i) => {
                    if (p.error) {
                        newErrors.push({ id: i, text: p.error, active: true })
                    }
                })
                if (newErrors.length > 0) {
                    setErrors(newErrors)
                }
            }

            return "ok"
        }
    }

    const updateUserStatistics = async () => {
        const res = await getUserStatistics()

        if (res.status === 200) {
            if (currentUser) {
                setCurrentUser({ ...currentUser, statistics: res.data.data })
            }
        }
    }

    const updateUserRecords = async () => {
        const resultsRes = await getPersonalRecords(token)

        if (resultsRes.status === 200 && resultsRes.data.data) {
            setUserRecords(resultsRes.data.data)
        }
    }

    const handleDateChange = (date) => {
        if (plansData) {
            var newDay = plansData.allAvailableDays.filter((d) => d === date)

            if (newDay.length > 0) {
                setPlansData((current) => {
                    let newWorkoutDay = current.activePlan.workout_days.filter((d) => d.date === date)

                    return {
                        ...current,
                        activeDay: date,
                        workoutDay: newWorkoutDay.length > 0 ? newWorkoutDay[0] : null
                    }
                })
            }
        }
    }

    const updateWorkoutData = async () => {
        const res = await getAvailableWorkouts(token)

        if (res.status === 200 && res.data.data && res.data.data.length > 0) {
            const initialDate =
                new Date(router.query.day) == "Invalid Date"
                    ? getSlugFromDate(new Date())
                    : getSlugFromDate(new Date(router.query.day))

            let initialPlan = router.query.plan && res.data.data.find((p) => p.plan_name === router.query.plan)

            if (!initialPlan) {
                initialPlan = res.data.data[0]
            }

            let allAvailableDays = []

            if (res.data.data) {
                for (var i = -1 * initialPlan.days_before; i <= initialPlan.days_after; i++) {
                    allAvailableDays.push(getSlugFromDate(new Date(new Date().getTime() + i * 1000 * 3600 * 24), i))
                }
            }

            var days = []
            initialPlan.workout_days.forEach((d) => {
                days.push(d.date)
            })

            setPlansData({
                purchasedPlans: res.data.data,
                activePlan: initialPlan,
                activeDay: initialDate,
                activePlanDays: days,
                workoutDay: initialPlan.workout_days.find((day) => day.date === initialDate)
                    ? initialPlan.workout_days.find((day) => day.date === initialDate)
                    : null,
                allAvailableDays
            })
        }

        if (
            res.status === 403 &&
            res.data.message === "Your email address is not verified." &&
            [
                appRoutes.LOGIN.route,
                appRoutes.ONBOARDING.REGISTRATION.route,
                appRoutes.ONBOARDING.EMAIL_VERIFICATION.route,
                appRoutes.FORGOTTEN_PASSWORD.route,
                appRoutes.NEW_PASSWORD.route
            ].indexOf(router.pathname) === -1
        ) {
            setEmailVerified(false)
            router.replace(appRoutes.ONBOARDING.EMAIL_VERIFICATION.route)
        }

        if (res.status === 401 && unprotectedRoutes.indexOf(router.pathname) === -1) {
            router.replace(appRoutes.LOGIN.route)
        }
    }

    const changePlan = (planId) => {
        if (plansData) {
            let newPlan = plansData.purchasedPlans.filter((plan) => plan.plan_id === planId)

            let allAvailableDays = []

            if (newPlan && newPlan.length === 1) {
                for (var i = -1 * newPlan[0].days_before; i <= newPlan[0].days_after; i++) {
                    allAvailableDays.push(getSlugFromDate(new Date(new Date().getTime() + i * 1000 * 3600 * 24), i))
                }
                setPlansData((current) => {
                    return {
                        ...current,
                        activePlan: newPlan[0],
                        activePlanDays: newPlan[0].workout_days.map((d) => d.date),
                        workoutDay:
                            newPlan[0].workout_days.filter((d) => d.date === current.activeDay).length > 0
                                ? newPlan[0].workout_days.filter((d) => d.date === current.activeDay)[0]
                                : null,
                        allAvailableDays
                    }
                })
            }
        }
    }

    const updateAfterWorkout = async () => {
        if (plansData && plansData.purchasedPlans && plansData.activePlan && plansData.workoutDay) {
            setPlansData((current) => {
                let planIndex = current.purchasedPlans.indexOf(
                    current.purchasedPlans.filter((p) => p.plan_id === current.activePlan.plan_id)[0]
                )

                if (current.purchasedPlans[planIndex]) {
                    let theDay = current.purchasedPlans[planIndex].workout_days.filter(
                        (d) => d.id === current.workoutDay.id
                    )
                    if (theDay.length > 0) {
                        theDay[0].completed = true
                    }
                }

                let thePlanDay = current.activePlan.workout_days.filter((d) => d.id === current.workoutDay.id)
                if (thePlanDay.length > 0) {
                    thePlanDay[0].completed = true
                }

                current.workoutDay.completed = true

                return current
            })
        }
    }

    const fetchBanners = async () => {
        const res = await getBanners()

        if (res.status === 200) {
            setBanners(res.data.data)
        }
    }

    const fetchBillingInfo = async () => {
        const res = await getBillingInfo(token)
        if (res.status === 200) {
            setBillingInfo(res.data.data)
        }
    }

    const fetchSubscriptions = async () => {
        const res = await getUserSubscriptions(token)
        if (res.status === 200) {
            setSubscriptions(res.data.data)
        }
    }
    const getMaintenanceMode = async () => {
        const res = await checkMaintenanceMode()
        if (res.status === 200) {
            if (res.data.data.maintenance_mode === false) {
                setMaintenanceMode(false)
            }
            if (res.data.data.maintenance_mode === true) {
                setMaintenanceMode(true)
            }
        }
    }

    const getPublicProfile = async (userId) => {
        const res = await fetchPublicProfile(userId)

        if (res.status >= 300 || !res.data.data) {
            return router.replace("/404")
        }
        return setActivePublicProfile({ ...res.data.data, user_id: userId })
    }

    useEffect(() => {
        if (maintenanceMode && router.pathname !== appRoutes.MAINTENANCE.route) {
            router.replace(appRoutes.MAINTENANCE.route)
        }
        if (maintenanceMode === false && router.pathname === appRoutes.MAINTENANCE.route) {
            router.replace(appRoutes.DASHBOARD.route)
        }
    }, [maintenanceMode])

    useEffect(() => {
        !currentUser && fetchUser()
        if (currentUser && currentUser.profile_completed && !userRecords) {
            updateUserRecords()
        }

        if (
            currentUser &&
            !currentUser.profile_completed &&
            [
                appRoutes.ONBOARDING.YOUR_PROFILE.route,
                appRoutes.ONBOARDING.REGISTRATION.route,
                appRoutes.ONBOARDING.EMAIL_VERIFICATION.route,
                appRoutes.LOGIN.route,
                appRoutes.FORGOTTEN_PASSWORD.route,
                ...unprotectedRoutes
            ].indexOf(router.pathname) === -1
        ) {
            router.replace(appRoutes.ONBOARDING.YOUR_PROFILE.route)
        }
    }, [currentUser])

    var noPlanSafeRoutes = [
        appRoutes.ONBOARDING.REGISTRATION_SUCCESFULL.route,
        appRoutes.TUTORIAL.route,
        appRoutes.NO_PLAN.route,
        "/platba/[id]",
        "/platba/[id]/uspesna-platba",
        "/platba/[id]/neuspesna-platba",
        appRoutes.SUBSCRIPTION_SELECT.route,
        appRoutes.PAYMENT.SUCCESSFUL.route,
        appRoutes.PAYMENT.UNSUCCESSFUL.route,
        appRoutes.LOGIN.route,
        appRoutes.FORGOTTEN_PASSWORD.route,
        appRoutes.NEW_PASSWORD.route
    ]

    useEffect(() => {
        if (
            currentUser &&
            currentUser.profile_completed &&
            currentUser.active_plans.length === 0 &&
            noPlanSafeRoutes.indexOf(router.pathname) === -1 &&
            !justCompletedProfile
        ) {
            router.replace(appRoutes.NO_PLAN.route)
        }

        if (!plansData) {
            updateWorkoutData()
        }
        if (!userRecords) {
            updateUserRecords()
        }

        if (currentUser && !currentUser.statistics) {
            updateUserStatistics()
        }

        if (!banners) {
            fetchBanners()
        }

        if (!billingInfo) {
            fetchBillingInfo()
        }
        if (!subscriptions) {
            fetchSubscriptions()
        }
        if (
            [appRoutes.COMMUNITY.route, appRoutes.PUBLIC_PROFILE.route].indexOf(router.pathname) === -1 &&
            communitySearch.text !== ""
        ) {
            setCommunitySearch((current) => {
                return { ...current, text: "" }
            })
        }
    }, [router, currentUser])

    useEffect(() => {
        if (
            router &&
            plansData &&
            plansData.activePlanDays.indexOf(plansData.activeDay) === -1 &&
            [
                appRoutes.WORKOUT_PAGE.ACTIVE_WORKOUT.route,
                appRoutes.WORKOUT_PAGE.ACTIVE_WORKOUT.COMMENTS.route,
                appRoutes.WORKOUT_PAGE.ACTIVE_WORKOUT.RESULTS.route
            ].indexOf(router.pathname) === -1 &&
            plansData.workoutDay !== null
        ) {
            return setPlansData((current) => {
                return { ...current, workoutDay: null }
            })
        }

        if (
            plansData &&
            plansData.activeDay &&
            plansData.activePlan &&
            plansData.workoutDay === null &&
            plansData.activePlan.workout_days.filter((d) => d.date === plansData.activeDay).length > 0
        ) {
            return setPlansData((current) => {
                return {
                    ...current,
                    workoutDay: plansData.activePlan.workout_days.filter((d) => d.date === plansData.activeDay)[0]
                }
            })
        }

        if (currentUser && !currentUser.statistics) {
            updateUserStatistics()
        }
    }, [plansData])

    const handleConsentAccepted = (cookie) => {
        if (cookie && cookie.level && isProductionNode()) {
            if (cookie.level.indexOf("analytics") !== -1) {
                // GOOGLE ANALYTICS
                ReactGA.initialize("G-2W808W7P99")
                ReactGA.pageview(window.location.pathname + window.location.search)
            }

            // FACEBOOK PIXEL INIT
            if (cookie.level && cookie.level.indexOf("targeting") !== -1) {
                import("react-facebook-pixel")
                    .then((x) => x.default)
                    .then((ReactPixel) => {
                        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID)
                        ReactPixel.pageView()
                    })

                // FIREBASE PERFORMANCE
                const firebase = initializeApp(firebaseConfig)
                getPerformance(firebase)
            }
        }
    }

    const handleCookieConsent = async () => {
        await import("../components/cookie/cookieconsent")

        const cc = initCookieConsent()

        cookieconsentConfig.onAccept = (res) => {
            handleConsentAccepted(res)
        }
        cc.run(cookieconsentConfig)
    }

    useEffect(() => {
        if (clientSide && !cookieChecked) {
            let consent = getCookie("cc_cookie")

            if (consent && !consentCookie) {
                setConsentCookie(JSON.parse(consent))
                handleConsentAccepted(JSON.parse(consent))
                setCookieChecked(true)
            }
            if (!consent) {
                handleCookieConsent()
                setCookieChecked(true)
            }
        }
        if (clientSide && maintenanceMode === null) {
            getMaintenanceMode()
        }
    }, [clientSide])

    useEffect(() => {
        // FACEBOOK PIXEL PAGEVIEW
        if (
            clientSide &&
            isProductionNode() &&
            consentCookie &&
            consentCookie.level &&
            consentCookie.level.indexOf("targeting") !== -1
        ) {
            import("react-facebook-pixel")
                .then((x) => x.default)
                .then((ReactPixel) => {
                    ReactPixel.pageView()

                    router.events.on("routeChangeComplete", () => {
                        ReactPixel.pageView()
                    })
                })
        }
    }, [router.events])

    return (
        <UserContext.Provider
            value={{
                currentUser,
                fetchUser,
                userRecords,
                updateUserRecords,
                plansData,
                handleDateChange,
                updateWorkoutData,
                emailVerified,
                setEmailVerified,
                updateUserStatistics,
                changePlan,
                banners,
                errors,
                setErrors,
                updateAfterWorkout,
                billingInfo,
                fetchBillingInfo,
                setJustCompletedProfile,
                consentCookie,
                subscriptions,
                activePublicProfile,
                getPublicProfile,
                publicProfiles,
                setPublicProfiles,
                communitySearch,
                setCommunitySearch
            }}
        >
            {children}
        </UserContext.Provider>
    )
}
