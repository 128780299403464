import styled, { css } from "styled-components"
import { fontWeight, color } from "../../../styles/global-style"

export const LightParagraph = styled.p`
    font-weight: ${fontWeight.normal};
    font-family: Helvetica;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : props.margin ? props.margin : 0)}px;
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : props.margin ? props.margin : 0)}px;
`

export const MarginParagraph = styled.p`
    margin-top: ${(props) => (props.marginTop ? props.marginTop : props.margin ? props.margin : 0)}px;
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : props.margin ? props.margin : 0)}px;
    ${({ color }) =>
        color &&
        css`
            color: ${color};
        `}
`

export const LinkButton = styled.span`
    color: ${color.floral};
    cursor: pointer;
`

export const SmallHeading = styled.h4`
    color: ${color.floral};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : props.margin && props.margin)}px;
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : props.margin && props.margin)}px;
`

export const PurpleHeading = styled(SmallHeading)`
    ${({ lowerCase }) =>
        !lowerCase &&
        css`
            text-transform: uppercase;
        `}
`

export const SmallPurpleHeading = styled.h5`
    color: ${color.floral};
    text-transform: uppercase;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : props.margin && props.margin)}px;
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : props.margin && props.margin)}px;
`

export const LargeHeading = styled.h1`
    font-size: 58px;
    line-height: 70px;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : props.margin && props.margin)}px;
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : props.margin && props.margin)}px;
`

export const GreyHeading = styled.h4`
    color: ${color.philippine};
    font-size: 15px;
`

export const SmallGreyHeading = styled.h6`
    color: ${color.philippine};
`

export const InlineSubmitButton = styled.button`
    background: transparent;
    border: 0;
    color: ${color.floral};
`

export const PurpleLink = styled.a`
    color: ${color.lavender};
`
