import { Popup } from "../ui/popups"
import Calendar from "react-calendar"
import styled from "styled-components"
import "react-calendar/dist/Calendar.css"
import { CloseButton } from "../ui/buttons"
import { NoScroll } from "../../styles/dashboard"
import CalendarArrowLeftIcon from "../icons/calendar-arrow-left"
import CalendarArrowRightIcon from "../icons/calendar-arrow-right"

const CalendarPopup = ({ calendarValue, handleCalendarChange, disabledDays, setShowCalendar }) => {
    return (
        <Popup paddingBottom={40} paddingTop={20}>
            <NoScroll />
            <Calendar
                value={calendarValue}
                maxDetail="month"
                minDetail="month"
                locale="cs"
                tileDisabled={({ date }) => disabledDays(date)}
                onChange={handleCalendarChange}
                nextLabel={<CalendarArrowRightIcon />}
                prevLabel={<CalendarArrowLeftIcon />}
            />
            <CloseBtn onClick={() => setShowCalendar(false)} />
        </Popup>
    )
}

export default CalendarPopup

const CloseBtn = styled(CloseButton)`
    display: flex;
    position: absolute;
    top: 30px;
    right: 30px;
`
