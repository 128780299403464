export const announcements = {
    todayFinished: "Máš odcvičeno ✔️",
    availableDays: "Dostupné tréninky",
    activePlan: "Aktivní členství",
    activePlans: "Aktuálně koukáš na tréninkový program",
    otherPlans: "Další plány",
    todayWorkout: "Dneska si jedu",
    planTypes: {
        monthly: "Měsíční"
    },
    planStatus: {
        active: "Aktivní",
        cancelled: "Zrušeno",
        expiring: "Brzy vyprší"
    }
}

export const userStatistics = [
    "Počet odcvičených workoutů",
    "Počet dní s JNV",
    "Počet dřepů",
    "Počet angličáků",
    "Počet kliků",
    "Uběhnutá vzdálenost"
]

export const weekDays = ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"]
