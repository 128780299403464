import { useRouter } from "next/router"
import { createContext, useContext, useEffect, useState } from "react"
import { appRoutes } from "../constants/app-routes"
import { UserContext } from "./user-context"

export const WorkoutContext = createContext()

export const WorkoutProvider = ({ children }) => {
    const [activeBlock, setActiveBlock] = useState(null)
    const [activePart, setActivePart] = useState(0)
    const [videoNotLoaded, setVideoNotLoaded] = useState(true)
    const [showContinuePopup, setShowContinuePopup] = useState(false)
    const [startFrom, setStartFrom] = useState(null)
    const [workoutId, setWorkoutId] = useState(null)
    const [activeWorkout, setActiveWorkout] = useState(null)

    const { handleDateChange, plansData, changePlan } = useContext(UserContext)

    const router = useRouter()

    const updateWorkout = (data) => {
        if (data) {
            setActiveWorkout({ ...data.workout, date: data.date, planId: data.plan_id })
            setWorkoutId(data.id)

            data &&
                data.workout &&
                data.workout.exercise_blocks &&
                setActiveBlock({ ...data.workout.exercise_blocks[0], activeIndex: 0 })

            for (let i = 0; i < data.workout.exercise_blocks.length; i++) {
                const block = data.workout.exercise_blocks[i]

                if (!block.result) {
                    if (i === 0) {
                        break
                    }
                    setStartFrom(i)
                    return setShowContinuePopup(true)
                }
            }
        }
    }

    useEffect(() => {
        if (
            activeWorkout &&
            plansData &&
            [
                appRoutes.WORKOUT_PAGE.ACTIVE_WORKOUT.route,
                appRoutes.WORKOUT_PAGE.ACTIVE_WORKOUT.COMMENTS.route,
                appRoutes.WORKOUT_PAGE.ACTIVE_WORKOUT.RESULTS.route
            ].indexOf(router.pathname) !== -1
        ) {
            if (plansData.activePlan && plansData.activePlan.plan_id !== activeWorkout.planId) {
                changePlan(activeWorkout.planId)
            }

            if (plansData.activeDay && activeWorkout.date !== plansData.activeDay) {
                handleDateChange(activeWorkout.date)
            }
        }
    }, [router, activeWorkout, plansData])

    return (
        <WorkoutContext.Provider
            value={{
                workoutId,
                activeWorkout,
                setActiveWorkout,
                activeBlock,
                setActiveBlock,
                activePart,
                setActivePart,
                startFrom,
                setShowContinuePopup,
                updateWorkout,
                videoNotLoaded,
                setVideoNotLoaded,
                showContinuePopup
            }}
        >
            {children}
        </WorkoutContext.Provider>
    )
}
