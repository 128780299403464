import axios from "axios"
import { getAppHeaders } from "../constants/api-requests"
import { token } from "./auth"

/*
========WORKOUT========
*/

export const getAvailableWorkouts = async (token) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/workout`, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const getWorkoutDetail = async (id, token) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/workout/${id}`, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const newWorkoutResult = async (workoutId, exerciseBlockId, body) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.post(
            `${process.env.NEXT_PUBLIC_API_URL}/workout/${workoutId}/result/${exerciseBlockId}`,
            body,
            { headers }
        )

        return res
    } catch (error) {
        return error.response
    }
}

export const getExerciseBlockResults = async (workoutId, exerciseBlockId) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.get(
            `${process.env.NEXT_PUBLIC_API_URL}/workout/${workoutId}/result/${exerciseBlockId}`,
            { headers }
        )

        return res
    } catch (error) {
        return error.response
    }
}

/*
========WORKOUT COMMENTS========
*/

export const getWorkoutComments = async (workoutId, token) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/workout/${workoutId}/comment`, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const createComment = async (workoutId, data) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/workout/${workoutId}/comment`, data, {
            headers
        })

        return res
    } catch (error) {
        return error.response
    }
}

export const updateComment = async (workoutId, commentId, data) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.put(
            `${process.env.NEXT_PUBLIC_API_URL}/workout/${workoutId}/comment/${commentId}`,
            data,
            { headers }
        )

        return res
    } catch (error) {
        return error.response
    }
}

export const deleteComment = async (workoutId, commentId) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.delete(`${process.env.NEXT_PUBLIC_API_URL}/workout/${workoutId}/comment/${commentId}`, {
            headers
        })

        return res
    } catch (error) {
        return error.response
    }
}

export const giveLike = async (workoutId, commentId) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios({
            method: "post",
            url: `${process.env.NEXT_PUBLIC_API_URL}/workout/${workoutId}/comment/${commentId}/like`,
            data: {},
            headers
        })

        return res
    } catch (error) {
        return error.response
    }
}

export const removeLike = async (workoutId, commentId) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.delete(
            `${process.env.NEXT_PUBLIC_API_URL}/workout/${workoutId}/comment/${commentId}/like`,
            {
                headers
            }
        )

        return res
    } catch (error) {
        return error.response
    }
}
