import styled from "styled-components"
import { color, zIndex } from "../../styles/global-style"

const LoadingScreen = ({ isLoading }) => {
    return (
        <Background isLoading={isLoading}>
            <img src={"/assets/JNV-video-loading.gif"} width={80} height={80} alt={"logo"} />
        </Background>
    )
}

export default LoadingScreen

const Background = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${(props) => (props.isLoading ? zIndex.loadingModal : -1)};
    background: ${color.white};

    visibility: ${(props) => (props.isLoading ? "visible" : "hidden")};
    opacity: ${(props) => (props.isLoading ? 1 : 0)};
    display: flex;
    justify-content: center;
    align-items: center;

    * {
        overflow: visible !important;
    }

    img {
        object-fit: contain;
    }
`
