import axios from "axios"
import { isBrowser } from "../components/protected-route"
import { getAppHeaders } from "../constants/api-requests"

export const getCookie = (name) => {
    let value = "; " + document.cookie
    let parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(";").shift()
}

export const cookieParser = (name, cookies) => {
    let value = "; " + cookies
    let parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(";").shift()
}

export const token = isBrowser() && getCookie("access_token")

export const createCookie = (token) => {
    // 30 days
    const maxAge = 60 * 60 * 24 * 30
    document.cookie = `access_token=${token};Max-Age=${maxAge};path=/;`
}

export const createSocialCookie = (token, maxAge) => {
    document.cookie = `access_token=${token};Max-Age=${maxAge};path=/;`
}

export const deleteCookie = (name) => {
    if (getCookie(name)) {
        document.cookie = name + "=;Max-Age=0;"
    }
}

export const handleLogin = async (props) => {
    const headers = getAppHeaders()

    const payload = {
        email: props.email,
        password: props.password
    }

    try {
        const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/auth/sign-in`, payload, {
            headers
        })

        return res
    } catch (error) {
        return error.response
    }
}

export const handleRegistration = async (props) => {
    const headers = getAppHeaders()

    const payload = {
        email: props.email,
        password: props.password,
        password_confirmation: props.password_confirmation
    }

    try {
        const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/auth/sign-up`, payload, {
            headers
        })

        return res
    } catch (error) {
        return error.response
    }
}

export const getSocialAuth = async (token, provider) => {
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json"
    }

    const payload = {
        token
    }

    try {
        const res = await axios({
            method: "post",
            url: `${process.env.NEXT_PUBLIC_API_URL}/auth/provider/${provider}`,
            data: payload,
            headers
        })
        return res
    } catch (error) {
        return error.response
    }
}

export const handleVerificationEmail = async () => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/auth/send-verify`, {}, { headers })
        return res
    } catch (error) {
        return error.code
    }
}

export const sendEmailWithPasswordReset = async (values) => {
    const headers = getAppHeaders()

    try {
        const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/auth/password/reset`, values, {
            headers
        })

        return res
    } catch (error) {
        return error.response
    }
}

export const handleSignOut = async (token) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.post(
            `${process.env.NEXT_PUBLIC_API_URL}/auth/sign-out`,
            {},
            {
                headers
            }
        )

        return res
    } catch (error) {
        return error.response
    }
}

export const handleNewForgottenPassword = async (values) => {
    const payload = values

    const headers = getAppHeaders()

    try {
        const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/auth/password/set`, payload, {
            headers
        })

        return res
    } catch (error) {
        return error.response
    }
}

export const handleChangePassword = async (values) => {
    const payload = values

    const headers = getAppHeaders(token)

    try {
        const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/user/password/change`, payload, {
            headers
        })

        return res
    } catch (error) {
        return error.response
    }
}

export const handleSetupProfile = async (values) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/auth/setup-profile`, values, {
            headers
        })

        return res
    } catch (error) {
        return error.response
    }
}
