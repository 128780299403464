/* eslint-disable react/jsx-no-target-blank */
import styled, { keyframes } from "styled-components"
import { zIndex } from "../styles/global-style"

const UnsupportedBrowser = () => {
    return (
        <Container>
            <h1>Nepodporovaný prohlížeč</h1>
            <h2>Tvůj prohlížeč: Internet Explorer</h2>
            <p>
                Omlouváme se, ale tvůj internetový prohlížeč není podporovaný. Pro zobrazení aplikace si prosím stáhni
                podporovaný internetový prohlížeč kliknutím na ikonu níže.
            </p>
            <BrowsersContainer>
                <a href="https://www.google.com/intl/cs_CZ/chrome/" target="_blank" rel="norefferer">
                    <img src="/assets/browsers/chrome-icon.png" alt="" />
                </a>
                <a href="https://www.mozilla.org/cs/firefox/new/" target="_blank" rel="norefferer">
                    <img src="/assets/browsers/firefox-icon.png" alt="" />
                </a>
                <a href="https://www.microsoft.com/cs-cz/edge" target="_blank" rel="norefferer">
                    <img src="/assets/browsers/edge-icon.png" alt="" />
                </a>
            </BrowsersContainer>
        </Container>
    )
}

export default UnsupportedBrowser

const animation = keyframes`
    0% {
        visibility: hidden;
    }
    99% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    z-index: ${zIndex.unsupportedBrowserModal};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    background: #bc77ec;
    visibility: visible;
    animation: ${animation} 10s;

    * {
        color: white;
    }

    h1 {
        font-size: 28px;
        line-height: 42px;
        font-weight: 600;
        font-family: "Basier Square", sans-serif;
    }

    h2 {
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
        font-family: "Basier Square", sans-serif;
    }

    p {
        width: 400px;
        margin: 30px auto;
        text-align: center;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        font-family: Helvetica, sans-serif;
    }
`

const BrowsersContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 85px;
        height: 85px;
        object-fit: contain;
        margin: 30px;
    }
`
