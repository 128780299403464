import styled from "styled-components"
import { breakpoint, color, radius, sizes, transitionDuration, zIndex } from "../../styles/global-style"
import { useRouter } from "next/router"
import { appRoutes } from "../../constants/app-routes"
import { HomeIcon } from "../sidebar/icons/home-icon"
import { ProfileIcon } from "../sidebar/icons/profile-icon"
import { TrophyIcon } from "../sidebar/icons/trophy-icon"
import { LogoutIcon } from "../sidebar/icons/logout-icon"
import { useWindowSize } from "../../utils"
import { useContext, useEffect, useState } from "react"
import ReactTooltip from "react-tooltip"
import { UserContext } from "../../context/user-context"
import { logoutUser } from "../../utils/user"
import { SearchIcon } from "../sidebar/icons/search-icon"

const Sidebar = ({ setShowLoading }) => {
    const router = useRouter()
    const pageCategory = router.pathname
    const { plansData } = useContext(UserContext)

    const windowSize = useWindowSize()
    const [isTablet, setIsTablet] = useState(true)

    const handleProfile = () => {
        router.push(appRoutes.ACCOUNT_SETTINGS.route)
    }

    const handleDashboard = () => {
        return router.push({
            pathname: appRoutes.DASHBOARD.route,
            query: {
                plan: plansData && plansData.activePlan ? plansData.activePlan.plan_name : "",
                day: plansData ? plansData.activeDay : ""
            }
        })
    }

    const handleStatistics = () => {
        router.push(appRoutes.STATISTICS.route)
    }

    const buttonColor = (button) => {
        return button === pageCategory ? color.lavender : color.charleston
    }

    const activeButton = (route) => {
        return router.pathname === route
    }

    const handleLogout = async () => {
        setShowLoading(true)

        try {
            await logoutUser()
            setTimeout(() => {
                document.location = appRoutes.LOGIN.route
                return
            }, 300)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        Number(windowSize[0]) < 1024 ? setIsTablet(true) : setIsTablet(false)
        ReactTooltip.rebuild()
    }, [windowSize])

    return (
        <Wrapper>
            <LogoWrapper>
                <img onClick={handleDashboard} src="/assets/jnv-logo.svg" width={85} height={30} alt="Logo" />
            </LogoWrapper>
            <ControlSection>
                <ControlButton
                    isActive={activeButton(appRoutes.DASHBOARD.route)}
                    onClick={handleDashboard}
                    data-tip={!isTablet ? "Dashboard" : ""}
                    data-place="right"
                >
                    <HomeIcon color={buttonColor(appRoutes.DASHBOARD.route)} />
                </ControlButton>
                <ControlButton
                    isActive={activeButton(appRoutes.STATISTICS.route)}
                    onClick={handleStatistics}
                    data-tip={!isTablet ? "Statistiky" : ""}
                    data-place="right"
                >
                    <TrophyIcon color={buttonColor(appRoutes.STATISTICS.route)} />
                </ControlButton>
                <ControlButton
                    isActive={activeButton(appRoutes.COMMUNITY.route)}
                    onClick={() => router.push(appRoutes.COMMUNITY.route)}
                    data-tip={!isTablet ? "Komunita" : ""}
                    data-place="right"
                >
                    <SearchIcon color={activeButton(appRoutes.COMMUNITY.route) ? color.floral : color.charleston} />
                </ControlButton>
                <ControlButton
                    isActive={
                        activeButton(appRoutes.ACCOUNT_SETTINGS.route) || activeButton(appRoutes.SUBSCRIPTION.route)
                    }
                    onClick={handleProfile}
                    data-tip={!isTablet ? "Profil" : ""}
                    data-place="right"
                >
                    <ProfileIcon
                        color={
                            activeButton(appRoutes.ACCOUNT_SETTINGS.route) || activeButton(appRoutes.SUBSCRIPTION.route)
                                ? color.floral
                                : color.charleston
                        }
                    />
                </ControlButton>
            </ControlSection>
            <LogoutSection>
                <ControlButton onClick={handleLogout} data-tip={!isTablet ? "Odhlásit se" : ""} data-place="right">
                    <LogoutIcon color={color.charleston} />
                </ControlButton>
            </LogoutSection>
        </Wrapper>
    )
}

export default Sidebar

const Wrapper = styled.div`
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: ${zIndex.sidebar};
    width: 100%;
    background: ${color.white};
    box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.05);
    border-radius: 26px 26px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    min-width: ${sizes.tablet};

    ${breakpoint(sizes.desktopS)} {
        min-width: auto;
        display: block;
        padding-top: 40px;
        padding-bottom: 60px;
        background: ${color.lavender};
        min-height: 600px;
        width: 80px;
        top: 1rem;
        bottom: 1rem;
        left: 1rem;
        border-radius: 26px;
    }

    ${breakpoint("1100px")} {
        width: 152px;
    }

    ${breakpoint("1440px")} {
        left: calc((100% - ${sizes.desktopL}) / 2);
        left: max(calc((100% - ${sizes.desktopL}) / 2), 1rem);
    }
`

const LogoWrapper = styled.div`
    display: none !important;
    ${breakpoint(sizes.desktopS)} {
        display: block !important;
        padding: 0 10px;
        img {
            width: 100%;
            cursor: pointer;
        }
    }
    ${breakpoint("1100px")} {
        img {
            width: auto;
        }
    }
`

const ControlSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    ${breakpoint(sizes.desktopS)} {
        margin-top: 100px;
        display: block;
        width: 100%;
        transition: margin-top ${transitionDuration};
        svg path {
            fill: white;
        }
        #flash_icon path {
            fill: none;
            stroke: white;
        }

        @media screen and (max-height: 640px) {
            margin-top: 70px;
        }
    }
`

const ControlButton = styled.button`
    width: 50px;
    height: 50px;
    background: ${(props) => (props.isActive ? "rgba(255, 255, 255, .2)" : "transparent")};
    z-index: 20;
    border-radius: ${radius.main};
    display: block;
    margin: 0 auto;
    transition: background 0.2s;

    &:hover {
        background: rgba(0, 0, 0, 0.15);
    }
    ${breakpoint(sizes.desktopS)} {
        margin: 0 auto 25px;
        &:hover {
            background: ${(props) => (props.isActive ? "rgba(255, 255, 255, .25)" : "rgba(255, 255, 255, .2)")};
        }
    }
`

const LogoutSection = styled.div`
    margin: 0 20px 0 0;
    ${breakpoint(sizes.desktopS)} {
        margin-top: auto;
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
        transition: bottom ${transitionDuration};
        svg path {
            fill: white;
        }

        @media screen and (min-height: 900px) {
            bottom: 160px;
        }
    }
`
