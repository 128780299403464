export const TrophyIcon = ({ color }) => {
    return (
        <svg id="flash_icon" width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.8991 11.7065L15.4217 11.7068L5.29312 20.9532L7.13093 14.1146L7.46942 12.8552L6.16523 12.8551L2.45741 12.855C2.70181 12.6229 2.96638 12.3725 3.24778 12.1067C4.65756 10.7754 6.4818 9.06822 8.2885 7.38273C9.95411 5.82886 11.6038 4.29443 12.8983 3.09172L10.933 10.4484L10.597 11.7064L11.8991 11.7065Z"
                stroke={color ? color : "white"}
                strokeWidth="2"
            />
        </svg>
    )
}
