import axios from "axios"
import { getAppHeaders } from "../constants/api-requests"
import { token } from "./auth"

export const getAllPlans = async (token) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/plan`, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const getBillingInfo = async (token) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/user/billing-info`, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const updateBillingInfo = async (values) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.put(`${process.env.NEXT_PUBLIC_API_URL}/user/billing-info`, values, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const buyPlan = async (payload) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/checkout/buy`, payload, {
            headers
        })

        return res
    } catch (error) {
        return error.response
    }
}

export const checkCoupon = async (payload) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/checkout/check-coupon`, payload, {
            headers
        })

        return res
    } catch (error) {
        return error.response
    }
}

export const notifyPayment = async (paymentId) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/gopay/notify?id=${paymentId}`, {
            headers
        })

        return res
    } catch (error) {
        return error.response
    }
}
