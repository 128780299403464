
const appHeaders = {
    public: {
        "Content-Type": "application/json",
        Accept: "application/json"
    }
}

export const getAppHeaders = (token) => {
    if (token) {
        return { ...appHeaders.public, Authorization: `Bearer ${token}` }
    }
    return appHeaders.public
}
