const gdpr_link = ""
const contact_link = "https://jedunavykon.cz/kontakt"

const cookieconsentConfig = {
    current_lang: "cs",
    autoclear_cookies: false, // default: false
    theme_css: "/cookieconsent.css",
    page_scripts: true, // default: false
    languages: {
        cs: {
            consent_modal: {
                title: "Používáme soubory cookie!",
                description:
                    'Dobrý den, tyto webové stránky používají nezbytné soubory cookie k zajištění svého správného fungování a sledovací soubory cookie k pochopení toho, jak s nimi pracujete. Ty se nastavují pouze po souhlasu. <button type="button" data-cc="c-settings" class="cc-link">Nech mě vybrat</button>',
                primary_btn: {
                    text: "Přijmout všechny",
                    role: "accept_all" // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: "Odmítnout všechny",
                    role: "accept_necessary" // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: "Předvolby souborů cookie",
                save_settings_btn: "Uložit nastavení",
                accept_all_btn: "Přijmout všechny",
                reject_all_btn: "Odmítnout všechny",
                close_btn_label: "Zavřít",
                cookie_table_headers: [],
                blocks: [
                    {
                        title: "Používání souborů cookie 📢",
                        description:
                            'Soubory cookie používáme k zajištění základních funkcí webových stránek a ke zlepšení vašeho online zážitku. U každé kategorie si můžete zvolit, zda se chcete přihlásit nebo odhlásit, kdykoli budete chtít. Další podrobnosti týkající se souborů cookie a dalších citlivých údajů naleznete v úplném znění <a href="' +
                            "https://jedunavykon.cz/zasady-ochrany-osobnich-udaju/" +
                            '" target="_blank" class="cc-link">zásady ochrany osobních údajů</a>.'
                    },
                    {
                        title: "Nezbytně nutné soubory cookie",
                        description:
                            "Tyto soubory cookie jsou nezbytné pro správné fungování těchto webových stránek. Bez těchto souborů cookie by webové stránky nefungovaly správně.<br /><a href='https://jedunavykon.cz/cookies/' target='_blank'>Cookies</a>",
                        toggle: {
                            value: "necessary",
                            enabled: true,
                            readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: []
                    },
                    {
                        title: "Výkonnostní a analytické soubory cookie",
                        description:
                            "Tyto soubory cookie umožňují webové stránce zapamatovat si volby, které jste provedli v minulosti.<br /><a href='https://jedunavykon.cz/cookies/' target='_blank'>Cookies</a>",
                        toggle: {
                            value: "analytics", // your cookie category
                            enabled: false,
                            readonly: false
                        }
                    },
                    {
                        title: "Soubory cookie pro reklamu a cílení",
                        description:
                            "Tyto soubory cookie shromažďují informace o tom, jak webové stránky používáte, které stránky jste navštívili a na které odkazy jste klikli. Všechny údaje jsou anonymizované a nelze je použít k vaší identifikaci.<br /><a href='https://jedunavykon.cz/cookies/' target='_blank'>Cookies</a>",
                        toggle: {
                            value: "targeting",
                            enabled: false,
                            readonly: false
                        }
                    },
                    {
                        title: "Více informací",
                        description:
                            'V případě jakýchkoli dotazů ohledně našich zásad týkajících se souborů cookie a vašich voleb nás prosím <a class="cc-link" href="' +
                            contact_link +
                            '"  target="_blank">kontaktujte</a>.'
                    }
                ]
            }
        }
    }
}

export default cookieconsentConfig
