import { appRoutes } from "./app-routes"

export const unprotectedRoutes = [
    appRoutes.LOGIN.route,
    appRoutes.ONBOARDING.REGISTRATION.route,
    appRoutes.FORGOTTEN_PASSWORD.route,
    appRoutes.NEW_PASSWORD.route,
    appRoutes.MOBILE_REGISTRATION_SUCCESSFUL.route,
    appRoutes.ACCOUNT_TRANSFER.route,
    appRoutes.MAINTENANCE.route
]
