import { color } from "../../styles/global-style"

const CalendarIcon = ({ isDisabled }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.6 14.4H14.4V4.8H1.6V14.4ZM14.4 1.6V0H12.8V1.6H8.8V0H7.2V1.6H3.2V0H1.6V1.6H0V16H16V1.6H14.4Z"
                fill={!isDisabled ? color.white : "#28282A"}
            />
        </svg>
    )
}

export default CalendarIcon
