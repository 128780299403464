const BackIcon = () => {
    return (
        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.07095 9.89949L3.82816 6.6567H10.3137V4.657H3.82816L7.07095 1.41421L5.65674 0L-0.000115871 5.65685L5.65674 11.3137L7.07095 9.89949Z"
                fill="white"
            />
        </svg>
    )
}

export default BackIcon
