import styled, { css } from "styled-components"
import { radius, color, gradient, fontSize, lineHeight } from "../../../styles/global-style"
import { useRouter } from "next/router"
import BackIcon from "../../icons/back-icon"
import TimerIcon from "../../icons/timer-icon"
import CloseIcon from "../../icons/close-icon"

export const ButtonTemplate = styled.button`
    outline: none;
    border: ${(props) => (props.border ? props.border : 0)};
    border-radius: ${radius.main};
    color: ${(props) => (props.color ? props.color : color.white)};
`

export const Button = styled(ButtonTemplate)`
    width: 100%;
    max-width: ${(props) => (!props.full ? "315px" : "auto")};
    padding: 10px 0;
    font-size: ${fontSize.button};
    line-height: ${lineHeight.button};
    text-transform: ${(props) => (props.textTransform ? props.textTransform : "uppercase")};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : props.margin ? props.margin : 8)}px;
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : props.margin ? props.margin : 8)}px;
`

export const TransparentButton = styled(Button)`
    background: none;
    color: ${color.charleston};
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : props.margin ? props.margin : 10)}px;
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : props.margin ? props.margin : 10)}px;
`

export const PurpleButton = styled(Button)`
    background: ${(props) => (props.isDisabled ? gradient.soft : gradient.main)};
`

export const GreyButton = styled(Button)`
    background: ${gradient.soft};
`

export const BlackButton = styled(Button)`
    background: ${(props) => (props.isDisabled ? gradient.soft : color.charleston)};
`

export const CustomButton = styled(Button)`
    background: ${(props) => (props.bg ? props.bg : gradient.soft)};
    ${(props) =>
        props.noClick &&
        css`
            cursor: default;
        `}
`

const BackButtonTemplate = styled(Button)`
    background: ${(props) => (props.isDisabled ? gradient.soft : color.charleston)};
    width: 40px !important;
    height: 40px !important;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TimeButtonTemplate = styled(BackButtonTemplate)`
    background: ${(props) => (props.isDisabled ? gradient.soft : color.floral)};
`

export const BackButton = (props) => {
    const router = useRouter()
    return (
        <BackButtonTemplate {...props} onClick={props.onClick ? props.onClick : () => router.back()}>
            <BackIcon />
        </BackButtonTemplate>
    )
}

export const TimeButton = (props) => {
    return (
        <TimeButtonTemplate {...props}>
            <TimerIcon />
        </TimeButtonTemplate>
    )
}

export const CloseButton = (props) => {
    return (
        <BackButtonTemplate {...props} isDisabled={true}>
            <CloseIcon />
        </BackButtonTemplate>
    )
}

export const PillButton = styled.button`
    color: ${(props) => (props.isDisabled ? color.charleston : color.white)};
    background: ${(props) => (props.isDisabled ? color.ghost_white : gradient.main)};
    width: fit-content;
    font-size: 16px;
    line-height: 24px;
    padding: ${(props) => (props.isDisabled ? "2px 15px" : "2px 16px")};
    border-radius: ${radius.main};
    border: ${(props) => (props.isDisabled ? `1px solid ${color.magnolia}` : 0)};
    display: block;
`

export const SquareButton = styled(Button)`
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    background: ${(props) => (props.isDisabled ? gradient.main : color.languid)};
    flex-shrink: 0;
`

export const MiniTransparentButton = styled.a`
    background: transparent;
    width: fit-content;
    border: 0;
    display: block;
    margin: 20px auto;
`
