import axios from "axios"
import { getAppHeaders } from "../constants/api-requests"
import { deleteCookie, handleSignOut, token } from "./auth"
import { prettyNumber } from "./index"

export const getPersonalRecords = async (token) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/user/personal-record`, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const createPersonalRecord = async (data) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/user/personal-record`, data, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const updatePersonalRecord = async (data, id) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.put(`${process.env.NEXT_PUBLIC_API_URL}/user/personal-record/${id}`, data, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const deletePersonalRecord = async (id) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.delete(`${process.env.NEXT_PUBLIC_API_URL}/user/personal-record/${id}`, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const getUserProfile = async () => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/user/me`, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const updateUserProfile = async (data) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/user/me`, data, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const getUserStatistics = async () => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/user/statistics`, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const getBanners = async () => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/banner`, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const getUserSubscriptions = async (token) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/user/subscriptions`, { headers })

        return res
    } catch (error) {
        return error.response
    }
}

export const revokeUserSubscription = async (id) => {
    const headers = getAppHeaders(token)

    try {
        const res = await axios.post(
            `${process.env.NEXT_PUBLIC_API_URL}/user/subscriptions/${id}/revoke`,
            {},
            { headers }
        )

        return res
    } catch (error) {
        return error.response
    }
}

export const logoutUser = async () => {
    await deleteCookie("access_token")
    return await handleSignOut(token)
}

export const fetchPublicProfile = async (userId) => {
    const headers = getAppHeaders(token)
    try {
        return await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/user/detail/${userId}`, { headers })
    } catch (error) {
        return error.response
    }
}

export const fetchPublicProfiles = async (params) => {
    const headers = getAppHeaders(token)
    try {
        return await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/user${params || ""}`, { headers })
    } catch (error) {
        return error.response
    }
}

export const userLikes = (user) => {
    if (user && user.likes) {
        return prettyNumber(user.likes)
    }
    return 0
}

export const toggleFavoriteUser = async (userId, newFavorite) => {
    const headers = getAppHeaders(token)
    return newFavorite
        ? await axios.put(`${process.env.NEXT_PUBLIC_API_URL}/user/favorite/${userId}`, {}, { headers })
        : await axios.delete(`${process.env.NEXT_PUBLIC_API_URL}/user/favorite/${userId}`, { headers })
}
