import { useRouter } from "next/router"
import { useContext, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { appRoutes, withSidebarRoutes } from "../../constants/app-routes"
import { UserContext } from "../../context/user-context"
import { breakpoint, sizes } from "../../styles/global-style"
import { mobilePage } from "../../utils/routing"
import GetAppPopup from "../get-app/get-app-popup"
import Header from "./header"
import Sidebar from "./sidebar"
import ReactTooltip from "react-tooltip"
import LoadingScreen from "../screens/loading-screen"
import { WorkoutProvider } from "../../context/workout-context"

const Layout = (props) => {
    const router = useRouter()
    const { currentUser } = useContext(UserContext)
    const [showLoading, setShowLoading] = useState(false)

    const forMobile = mobilePage(router)

    const isDashboard = () => {
        return currentUser && router.pathname === appRoutes.DASHBOARD.route
    }

    const showHeader = () => {
        return currentUser && withSidebarRoutes.indexOf(router.pathname) !== -1
    }

    useEffect(() => {
        !showHeader() && setShowLoading(false)
    }, [showHeader()])

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [currentUser])

    return (
        <Wrapper forMobile={forMobile} maxWidth={showHeader() || router.pathname === "/404"}>
            {showHeader() && (
                <>
                    <Header fullHeader={isDashboard()} />
                    <Sidebar setShowLoading={setShowLoading} />
                </>
            )}
            {!forMobile && <GetAppPopup />}

            <WorkoutProvider>
                <Main>{props.children}</Main>
            </WorkoutProvider>
            <LoadingScreen isLoading={showLoading} />
        </Wrapper>
    )
}

export default Layout

const Wrapper = styled.div`
    min-width: ${(props) => (!props.forMobile ? sizes.tablet : sizes.min)};

    ${({ maxWidth }) =>
        maxWidth &&
        css`
            ${breakpoint(sizes.desktopL)} {
                max-width: ${sizes.desktopL};
                margin: 0 auto;
                overflow-x: hidden;
            }
        `}
`

const Main = styled.main`
    min-height: calc(100vh - 142px);
`
