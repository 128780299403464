export const LogoutIcon = ({ color }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.392 5.06L5.93799 6.408L8.366 9H0V11H8.366L5.93799 13.544L7.392 14.906L12.063 9.958L7.392 5.06ZM10 0V4H12V2H18V18H12V16H10V20H20V0H10Z"
                fill={color || "white"}
            />
        </svg>
    )
}
