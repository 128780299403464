export const ProfileIcon = ({ color }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 12C13.785 12 16.958 14.214 17.784 18H2.216C3.042 14.214 6.215 12 10 12ZM6 6C6 3.794 7.794 2 10 2C12.206 2 14 3.794 14 6C14 8.206 12.206 10 10 10C7.794 10 6 8.206 6 6ZM13.758 10.673C15.124 9.574 16 7.89 16 6C16 2.686 13.314 0 10 0C6.686 0 4 2.686 4 6C4 7.89 4.876 9.574 6.242 10.673C2.583 12.048 0 15.445 0 20H20C20 15.445 17.417 12.048 13.758 10.673Z"
                fill={color ? color : "white"}
            />
        </svg>
    )
}
