export const HomeIcon = ({ color }) => {
    return (
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 18.0013H11V14.0039H5V18.0013H2V8.79329L7.98999 2.8072L14 8.81324V18.0013ZM15.948 8.0078L7.97198 0L0 7.99383V20H7V16.0026H9V20H16V19.97V8.0078H15.948Z"
                fill={color ? color : "white"}
            />
        </svg>
    )
}
